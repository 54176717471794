import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";

import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import MainContext from "../../helpers/MainContext";

export default class Home extends Component {
  static contextType = MainContext;
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    var contentsData = this.context.contentsData;

    return (
      <>
        <section className="faqs">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="web-heading text-center">
                  <h2>FAQs</h2>
                </div>

                <div className="faqs-content">
                  <Accordion
                    allowZeroExpanded
                  >
                    {contentsData && contentsData.faqs &&
                      contentsData.faqs.map((item, index) => {
                        return (
                          <AccordionItem key={index}>
                            <AccordionItemHeading>
                              <AccordionItemButton>{item.question}</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <div dangerouslySetInnerHTML={{
                                __html: item.answer,
                              }}></div>
                            </AccordionItemPanel>
                          </AccordionItem>
                        );
                      })}
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
