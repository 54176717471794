import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { NotificationContainer, NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/components/effect-coverflow/effect-coverflow.scss';

import "./assets/css/style.css";
import "./assets/css/media.css";

import MainContext from "./helpers/MainContext";
import Http from "./helpers/Http";
import ApiRoutes from "./helpers/ApiRoutes";

/** Layouts **/
import MainLayout from "./layouts/MainLayout";

/** routesItems **/
import routesItems from "./Routes";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentsData: null,
    };
  }

  componentDidMount() {
    this.getContentsData();
  }

  getContentsData = async () => {

    let path = ApiRoutes.GET_ALL_CONTENTS;
    const res = await Http("GET", path);

    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {

        this.setState({
          contentsData: resJson.data
        })

      } else {

        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Internal Server Error", "Error!", 3000);
    }
  };

  render() {

    return (
      <>
        <MainContext.Provider value={this.state}>
          <Router basename="/">
            <Switch>
              {(routesItems || []).map((item, idx) => {
                if (item.layout == "mainLayout") {
                  return (
                    <MainLayout
                      key={idx}
                      path={item.path}
                      component={item.component}
                    />
                  );
                }
              })}

              <Redirect to="/page-not-found" />
            </Switch>
          </Router>
          <NotificationContainer />
        </MainContext.Provider>
      </>
    );
  }
}

export default App;
