import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";

import MainContext from "../../helpers/MainContext";

export default class TermsConditions extends Component {
  static contextType = MainContext;
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    var contentsData = this.context.contentsData;

    return (
      <>
        <section className="terms-conditions">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="web-heading text-center">
                  <h2>Terms & Conditions</h2>
                </div>

                <div className="terms-conditions-content" dangerouslySetInnerHTML={{
                  __html: contentsData && contentsData.contents ? contentsData.contents.terms_conditions : "",
                }}></div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
