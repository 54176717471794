import React, { Component, useContext } from "react";
import { NavLink, Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import MainContext from "../../helpers/MainContext";

const currentYear = new Date().getFullYear();

export default class Header extends Component {
    static contextType = MainContext;
    constructor(props) {
        super(props);

        this.state = {
            showNavBar: false,
        };
    }

    componentDidMount() {
    }

    setActiveMenuItem = async (menuKey) => {
        localStorage.setItem("selectedMenu", menuKey);
    };

    render() {
        var selectedMenu = localStorage.getItem("selectedMenu") || "";
        var contentsData = this.context.contentsData;

        return (
            <>
                <footer className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 col-lg-4">
                                <div className="footer-contact">
                                    <h4>Contact Us</h4>
                                    <ul>
                                        {contentsData && contentsData.settings && contentsData.settings.official_email && (
                                            <li><a><i className="fas fa-envelope" /> {contentsData.settings.official_email}</a></li>
                                        )}
                                        {contentsData && contentsData.settings && contentsData.settings.official_phone && (
                                            <li><a><i className="fas fa-phone" /> {contentsData.settings.official_phone}</a></li>
                                        )}

                                        {contentsData && contentsData.settings && contentsData.settings.official_address && (
                                            <li><a><i className="fas fa-map-marker" /> {contentsData.settings.official_address}</a></li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-5 col-lg-5">
                                <div className="footer-contact">
                                    <h4>Follow Us</h4>
                                    <div className="follow">
                                        <ul>
                                            <li>
                                                <a href={contentsData && contentsData.settings ? contentsData.settings.social_media_links.facebook : ""} className="p-0" target="_blank">
                                                    <img src="./assets/social/facebook.png" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href={contentsData && contentsData.settings ? contentsData.settings.social_media_links.instagram : ""} className="p-0" target="_blank">
                                                    <img src="./assets/social/instagram.png" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href={contentsData && contentsData.settings ? contentsData.settings.social_media_links.linkedin : ""} className="p-0" target="_blank">
                                                    <img src="./assets/social/linkedin.png" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href={contentsData && contentsData.settings ? contentsData.settings.social_media_links.youtube : ""} className="p-0" target="_blank">
                                                    <img src="./assets/social/youtube.png" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href={contentsData && contentsData.settings ? contentsData.settings.social_media_links.twitter : ""} className="p-0" target="_blank">
                                                    <img src="./assets/social/twitter.png" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 col-md-3 col-lg-3">
                                <div className="footer-contactul">
                                    <h4>Quick Links</h4>
                                    <ul className="navbar-nav">
                                        <li
                                            className={"nav-item " + (selectedMenu == "privacy-policy" ? "active" : "")}
                                            onClick={() => this.setActiveMenuItem("privacy-policy")}
                                        >
                                            <Link to="/privacy-policy" className="p-0 nav-link">Privacy Policy</Link>
                                        </li>
                                        <li
                                            className={"nav-item " + (selectedMenu == "terms-conditions" ? "active" : "")}
                                            onClick={() => this.setActiveMenuItem("terms-conditions")}
                                        >
                                            <Link to="/terms-conditions" className="p-0">Terms & Conditions</Link>
                                        </li>
                                        <li
                                            className={"nav-item " + (selectedMenu == "faqs" ? "active" : "")}
                                            onClick={() => this.setActiveMenuItem("faqs")}
                                        >
                                            <Link to="/faqs" className="p-0">FAQs</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

                <section className="copy-right">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <p>Copyright © {currentYear + "-" + (currentYear + 1)} Navodaya App. All rights reserved.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
