/** MainLayout Components **/
import PageNotFound from "./components/mainLayout/PageNotFound";
import Home from "./components/mainLayout/Home";
import PrivacyPolicy from "./components/mainLayout/PrivacyPolicy";
import TermsConditions from "./components/mainLayout/TermsConditions";
import FAQs from "./components/mainLayout/FAQs";

/** Routes **/
const routesItems = [

  /** MainLayout Routes **/
  { path: "/page-not-found", component: PageNotFound, layout: "mainLayout" },
  { path: "/privacy-policy", component: PrivacyPolicy, layout: "mainLayout" },
  { path: "/terms-conditions", component: TermsConditions, layout: "mainLayout" },
  { path: "/faqs", component: FAQs, layout: "mainLayout" },
  { path: "/", component: Home, layout: "mainLayout" },
];

export default routesItems;
