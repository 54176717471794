import React, { Component, useContext } from "react";
import { NavLink, Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import MainContext from "../../helpers/MainContext";

export default class Header extends Component {
  static contextType = MainContext;
  constructor(props) {
    super(props);

    this.state = {
      showNavBar: false,
    };
  }

  componentDidMount() {
  }

  setActiveMenuItem = async (menuKey) => {
    localStorage.setItem("selectedMenu", menuKey);
  };

  render() {
    var selectedMenu = localStorage.getItem("selectedMenu") || "home";
    var contentsData = this.context.contentsData;

    return (
      <>
        <header>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <nav className="navbar navbar-expand-lg navbar-light">
                  <NavLink to="/" className="navbar-brand">
                    <img src="./assets/img/logo.png" alt="logo" />
                  </NavLink>
                  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation"
                    onClick={() => {
                      this.setState(prevState => ({
                        showNavBar: !prevState.showNavBar
                      }));
                    }}
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div className={"collapse navbar-collapse " + (this.state.showNavBar ? "show" : "")} id="navbarTogglerDemo02">
                    <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                      <li
                        className={"nav-item " + (selectedMenu == "home" ? "active" : "")}
                        onClick={() => this.setActiveMenuItem("home")}
                      >
                        <a href="/" className="nav-link">Home</a>
                      </li>

                      <li
                        className={"nav-item " + (selectedMenu == "aboutus" ? "active" : "")}
                        onClick={() => this.setActiveMenuItem("aboutus")}
                      >
                        <a href="/#about-us" className="nav-link">About Us</a>
                      </li>

                      <li
                        className={"nav-item " + (selectedMenu == "downloads" ? "active" : "")}
                        onClick={() => this.setActiveMenuItem("downloads")}
                      >
                        <a href="/#downloads" className="nav-link">Downloads</a>
                      </li>

                      <li
                        className={"nav-item " + (selectedMenu == "overviews" ? "active" : "")}
                        onClick={() => this.setActiveMenuItem("overviews")}
                      >
                        <a href="/#overview" className="nav-link">Overview</a>
                      </li>

                      <li
                        className={"nav-item " + (selectedMenu == "contactus" ? "active" : "")}
                        onClick={() => this.setActiveMenuItem("contactus")}
                      >
                        <a href="/#contact-us" className="nav-link">Contact Us</a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}
