import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';

import Header from './partials/Header';
import Footer from './partials/Footer';

const MainLayout = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={props => (
      <>
        <Header
          history={props.history}
        />
        <Component {...props} />
        <Footer />
      </>
    )} />
  )
};

export default MainLayout;