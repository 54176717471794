import { baseUrl, apiAccessKey } from "../config/config";
import jwt from "jsonwebtoken";

export default (method, path, formData = undefined) => {

  var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  var currentTimeStamp = Math.floor(Date.now() / 1000); // In seconds
  const accessToken = jwt.sign({}, apiAccessKey, {
    expiresIn: "1m"
  });

  const apiUrl = baseUrl + path;

  return fetch(apiUrl, {
    method: method,
    body: formData,
    headers: {
      "x-access-token": accessToken ? accessToken : "",
      "timezone": timeZone,
    },
  })
    .then((apiRes) => {
      // on login session expire error
      if ([401, 408].includes(apiRes.status)) {
        console.log("Error: " + apiRes.message);
      }

      return apiRes;
    })
    .catch((err) => {
      console.log(err.toString());
    });
};
